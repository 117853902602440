<template>
	<div>
		<!-- 导入其他科目余额表 -->
		<el-dialog :close-on-click-modal="false" destroy-on-close title="科目配置" v-model="dialogFormVisible" width="80%">
			<el-form ref="dataForm" :model="temp" label-position="right" label-width="120px" :inline="true">
				<div class="common_dialog_box clearfix">
					<div class="common_title">1.设置科目编码格式</div>
					<div class="item_one clearfix">
						<el-form-item label="编码分隔符:" class="item">
							<el-select v-model="temp.spe" placeholder="请选择编码分隔符" style="width:90%;" size="small"
								@change="changeCodeSpe">
								<el-option v-for="item in code_type" :key="item.value" :label="item.label"
									:value="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="科目名称分隔符:" class="item">
							<el-select v-model="temp.nameSpe" placeholder="请选择编码分隔符" style="width:90%;" size="small">
								<el-option v-for="item in code_type" :key="item.value" :label="item.label"
									:value="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="科目层级:" class="item">
							<el-select @change="changeLevel" v-model="temp.level" placeholder="请选择科目层级" style="width:90%;"
								size="small">
								<el-option v-for="item in level" :key="item.value" :label="item.label"
									:value="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="编码长度:" class="item">
							<span v-for="(item, index) in temp.len" :key="index">
								<el-select v-model="temp.len[index]" style="width:58px;margin-right:0px;margin-bottom:5px"
									size="small" placeholder="" @change="changeCodeLength(temp.len)">
									<el-option v-for="item in code_width" :key="item.value" :label="item.label"
										:value="item.label"></el-option>
								</el-select>
							</span>
						</el-form-item>
						<el-form-item label="编码示例:" class="item">
							<span>{{ exampleCode1 }}</span>
							<span v-if="this.temp.len.length >= 2">{{ codeSeparator }}</span>
							<span v-if="this.temp.len.length >= 2">{{ exampleCode2 }}</span>
							<span v-if="this.temp.len.length >= 3">{{ codeSeparator }}</span>
							<span v-if="this.temp.len.length >= 3">{{ exampleCode3 }}</span>
							<span v-if="this.temp.len.length >= 4">{{ codeSeparator }}</span>
							<span v-if="this.temp.len.length >= 4">{{ exampleCode4 }}</span>
							<span v-if="this.temp.len.length >= 5">{{ codeSeparator }}</span>
							<span v-if="this.temp.len.length >= 5">{{ exampleCode5 }}</span>
						</el-form-item>

						<el-form-item label="包含辅助核算:" class="item">
							<el-select v-model="temp.fzhsbz" placeholder="请选择辅助核算标志" style="width:90%;"
								size="small">
								<el-option v-for="item in fzhsbz" :key="item.value" :label="item.label"
									:value="item.label"></el-option>
							</el-select>
						</el-form-item>

					</div>
				</div>
				<div class="common_dialog_box clearfix">
					<div class="common_title">2.设置数据形式</div>
					<div class="item_one clearfix">
						<el-form-item label="期初数据形式:" class="item">
							<el-radio-group v-model="dataType">
								<el-radio :label="1">借贷两列</el-radio>
								<el-radio :label="2">方向+余额</el-radio>
								<el-radio :label="3">科目编码+科目名称一列(方向+余额)</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
				</div>
				<div class="common_dialog_box clearfix">
					<div class="common_title">3.余额表数据调整</div>
					<div class="item_one clearfix">
						<h6 style="color:red"><span>提示：</span>*请按照表头列次，调整导入数据的顺序</h6>
						<div class="table-big">

							<div class="table">
								<div v-if="dataType == 1"
									style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff;">
									<div class="tableHead" style="width:26px"></div>
									<div class="tableHead">科目编码</div>
									<div class="tableHead">科目名称</div>
									<div class="tableHead">期初余额借方</div>
									<div class="tableHead">期初余额贷方</div>
									<div class="tableHead">本期发生额借方</div>
									<div class="tableHead">本期发生额贷方</div>
									<div class="tableHead">累计发生额借方</div>
									<div class="tableHead">累计发生额贷方</div>
								</div>
								<div v-if="dataType == 2"
									style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff">
									<div class="tableHead" style="width:26px"></div>
									<div class="tableHead">科目编码</div>
									<div class="tableHead">科目名称</div>
									<div class="tableHead">方向</div>
									<div class="tableHead">期初余额</div>
									<div class="tableHead">本期发生额借方</div>
									<div class="tableHead">本期发生额贷方</div>
									<div class="tableHead">累计发生额借方</div>
									<div class="tableHead">累计发生额贷方</div>
								</div>
								<div v-if="dataType == 3"
									style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff">
									<div class="tableHead" style="width:26px"></div>
									<div class="tableHead">科目编码名称</div>
									<div class="tableHead">方向</div>
									<div class="tableHead">期初余额</div>
									<div class="tableHead">本期发生额借方</div>
									<div class="tableHead">本期发生额贷方</div>
									<div class="tableHead">累计发生额借方</div>
									<div class="tableHead">累计发生额贷方</div>
								</div>
								<draggable v-model="list" group="people" @start="drag = true" @end="drag = false" item-key="id">
									<template #item="{ element, index }">
										<div class="table-div">
											<div class="top_icon">
												<i @click="delItem(index)" class="iconfont icon-shanchu"></i>
											</div>
											<div v-for="(item2, index2) in element" :key="index2" class="table_icon">
												<span v-if="item2 != 'del'">
													{{ item2 }}
												</span>
												<span v-else>
													<i @click="delItemRaw(index2)" class="iconfont icon-shanchu"></i>
												</span>
											</div>
										</div>
									</template>
								</draggable>
							</div>
						</div>
					</div>
				</div>
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogFormVisible = false" size="small">取消</el-button>
					<el-button type="primary" @click="updateData()" size="small">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>

	<!-- 使用 -->
	<!-- <addSubject :addData="addData" @successAddSubject="successAddSubject" ref="addSubject"></addSubject>
  import addSubject from "@/components/subjectAndInventory/addSubject";
  this.$refs.addSubject.dialogVisibleSubject = true; -->
</template>
    

<script>

const delcommafy = function (num) {
	if (!num) return num;
	num = num.toString();
	num = num.replace(/,/gi, '');
	return num;
};

import draggable from 'vuedraggable'
export default {
	props: {

	},
	components: {
		draggable,
	},
	data() {
		return {
			list: [
				[1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008],
				[2, 2, 3, 4, 5, 67, 33, 8],
				[3, 2, 3, 4, 5, 67, 33, 8],
				[4, 2, 3, 4, 5, 67, 33, 8],
				[5, 2, 3, 4, 5, 67, 33, 8],
				[6, 2, 3, 4, 5, 67, 33, 8],
				[7, 2, 3, 4, 5, 67, 33, 8],
				[8, 2, 3, 4, 5, 67, 33, 8],
				[9, 2, 3, 4, 5, 67, 33, 8],
			],
			dataType: 1,
			dataCodeType: 1,
			temp: {
				level: "4",
				len: ['4', '2', '2', '2'],
				spe: "无",
				nameSpe: "无",
				fzhsbz:"",
			},
			level: [
				{ value: '1', label: '1' },
				{ value: '2', label: '2' },
				{ value: '3', label: '3' },
				{ value: '4', label: '4' },
				{ value: '5', label: '5' },
				{ value: '6', label: '6' }
			], //科目层级                      
			code_type: [{ value: '无', label: '无' }, { value: '.', label: '.' }, { value: '-', label: '-' }, { value: '_', label: '_' }, { value: '→', label: '→' }], //编码分隔符
			fzhsbz:[{ value: '[]', label: '[]' },],
			code_width: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' }], // 编码长度
			dialogFormVisible: false,
			exampleCode1: '1002',
			exampleCode2: '01',
			exampleCode3: '02',
			exampleCode4: '03',
			exampleCode5: '04',
			codeSeparator: '',
		};
	},
	created() {
	},
	methods: {
		init(data) {
			let long = 0
			data.map(v => {
				if (long < v.length) long = v.length
			})
			//console.log(data)
			let list = []
			// let list = [
			// 	['科目编码'],
			// 	['科目名称'],
			// 	['期初余额借方'],
			// 	['期初余额贷方'],
			// 	['本期余额借方'],
			// 	['本期余额贷方'],
			// 	['本年累计余额借方'],
			// 	['本年累计余额贷方']
			// ]
			data.map(v => {
				for (let i = 0; i < long; i++) {
					if (!list[i]) {
						list.push([])
					}
					if (v.length > i) {
						list[i].push(v[i])
					} else {
						list[i].push("")
					}

				}
			})
			//console.log(list)
			let dd = this.$qzfCopy(list[0])
			for (let i = 0; i < dd.length; i++) {
				dd[i] = 'del'
			}
			list.unshift(dd)

			// list.push(dd)
			//console.log(list)
			this.list = list
		},
		delItem(index) {
			// if(index == this.list.length-1){
			//     this.$message.error('本列不可删除');
			//     return
			// }
			this.list.splice(index, 1);
		},
		delItemRaw(index) {
			this.list.map(v => {
				v.splice(index, 1);
			})
		},
		changeLevel() {
			let lens = []
			for (let i = 0; i < this.temp.level * 1; i++) {
				lens.push("")
			}
			this.temp.len = lens
		},
		updateData() {
			if (this.list.length < 9 && (this.dataType != 3)) {
				this.$message.error('列数异常请退出当前页面重新导入');
				return
			}
			if (this.list.length < 8 && (this.dataType == 3)) {
				this.$message.error('列数异常请退出当前页面重新导入');
				return
			}
			if(this.dataType != 3){
				let str = ''
				for (let i = 0; i < this.list[1].length; i++) {
					if(this.list[1][i] && !this.list[2][i]){
						str = String(this.list[1][i]).trim() + '未填写科目名称，请检查模板'
						this.$message.error(str);
						return
					}
				}
			}
			let arr = []
			if (this.dataType == 1) {
				for (let i = 0; i < this.list[1].length; i++) {
					if (this.list[1][i] == null) {
						this.list[1][i] = ''
					} else {
						if (this.list[1][i].toString().replace(/\./g, "").replace(/\-/g, "").replace(/\_/g, "").replace(/[\ ]?([a-zA-Z])/g, "") * 1) {
							let subjectName = this.list[2][i].toString().trim()
							if (this.temp.nameSpe != "无") {
								subjectName = (subjectName.split(this.temp.nameSpe)[subjectName.split(this.temp.nameSpe).length - 1]).trim()
							}
							arr.push({
								periodBeginIn: delcommafy(this.list[3][i]) * 1,
								periodBeginOut: delcommafy(this.list[4][i]) * 1,
								yearInt: delcommafy(this.list[7][i]) * 1,
								yearOut: delcommafy(this.list[8][i]) * 1,
								periodInt: delcommafy(this.list[5][i]) * 1,
								periodOut: delcommafy(this.list[6][i]) * 1,
								// subjectCode: v["科目代码"].replace(".",""),
								// subjectName: v["科目名称"],
								origSubjectCode: this.list[1][i] ? String(this.list[1][i]).trim() : "",
								origSubjectName: subjectName.trim(),
							})
						}
					}
				}
			} else if (this.dataType == 2) {
				for (let i = 0; i < this.list[1].length; i++) {
					if (this.list[1][i]) {
						if (this.list[1][i].toString().replace(/\./g, "").replace(/\-/g, "").replace(/\_/g, "") * 1) {
							let periodBeginIn = 0
							let periodBeginOut = 0
							if (this.list[3][i] == null) {
								this.list[3][i] = '平'
							} else if (this.list[3][i].indexOf('借') > -1) {
								periodBeginIn = delcommafy(this.list[4][i]) * 1
							} else {
								periodBeginOut = delcommafy(this.list[4][i]) * 1
							}
							let subjectName = this.list[2][i].toString().trim()
							if (this.temp.nameSpe != "无") {
								subjectName = (subjectName.split(this.temp.nameSpe)[subjectName.split(this.temp.nameSpe).length - 1]).trim()
							}
							arr.push({
								periodBeginIn: delcommafy(periodBeginIn) * 1,
								periodBeginOut: delcommafy(periodBeginOut) * 1,
								yearInt: delcommafy(this.list[7][i]) * 1,
								yearOut: delcommafy(this.list[8][i]) * 1,
								periodInt: delcommafy(this.list[5][i]) * 1,
								periodOut: delcommafy(this.list[6][i]) * 1,
								// subjectCode: v["科目代码"].replace(".",""),
								// subjectName: v["科目名称"],
								origSubjectCode: this.list[1][i].toString().trim(),
								origSubjectName: subjectName.trim(),
							})
						}
					}
				}
			} else if (this.dataType == 3) {
				for (let i = 0; i < this.list[1].length; i++) {
					// if(this.list[0][i].toString().replace(/\./g, "").replace(/\-/g, "").replace(/\_/g, "")*1){
					let periodBeginIn = 0
					let periodBeginOut = 0
					if (this.list[2][i] == null) {
						this.list[2][i] = '平'
					} else if (this.list[2][i].indexOf('借') > -1) {
						periodBeginIn = delcommafy(this.list[3][i]) * 1
					} else {
						periodBeginOut = delcommafy(this.list[3][i]) * 1
					}
					let code = this.list[1][i].trim()
					var patt1 = new RegExp(`^[0-9,.]+`);
					code = patt1.exec(code).join('')
					let subjectName = this.list[1][i].trim()
					if (this.temp.nameSpe != "无") {
						subjectName = (subjectName.split(this.temp.nameSpe)[subjectName.split(this.temp.nameSpe).length - 1]).trim()
					}else{
						subjectName = subjectName.replace(code,"").trim()
					}
					arr.push({
						periodBeginIn: delcommafy(periodBeginIn) * 1,
						periodBeginOut: delcommafy(periodBeginOut) * 1,
						yearInt: delcommafy(this.list[6][i]) * 1,
						yearOut: delcommafy(this.list[7][i]) * 1,
						periodInt: delcommafy(this.list[4][i]) * 1,
						periodOut: delcommafy(this.list[5][i]) * 1,
						origSubjectCode: code,
						origSubjectName: subjectName.trim(),
						// origSubjectName: this.list[1][i].replace(code, "").trim(),
					})
					// }
					// //console.log(arr);
				}
			}
			this.dialogFormVisible = false
			// //console.log(arr)
			this.$emit("success",{arr:arr,temp:this.temp})
		},
		changeCodeSpe(e) {
			this.codeSeparator = e
			if (e == '无') {
				this.codeSeparator = ''
			}
		},
		changeCodeLength(e) {
			if (e[0] == '4') {
				this.exampleCode1 = '1002'
			} else if (e[0] == '3') {
				this.exampleCode1 = '122'
			} else if (e[0] == '5') {
				this.exampleCode1 = '10002'
			} else if (e[0] == '6') {
				this.exampleCode1 = '100002'
			} else if (e[0] == '7') {
				this.exampleCode1 = '1000002'
			} else if (e[0] == '8') {
				this.exampleCode1 = '10000002'
			} else if (e[0] == '2') {
				this.exampleCode1 = '12'
			} else if (e[0] == '1') {
				this.exampleCode1 = '2'
			}

			if (e[1] == '3') {
				this.exampleCode2 = '001'
			} else if (e[1] == '2') {
				this.exampleCode2 = '01'
			} else if (e[1] == '4') {
				this.exampleCode2 = '0001'
			} else if (e[1] == '5') {
				this.exampleCode2 = '00001'
			} else if (e[1] == '6') {
				this.exampleCode2 = '000001'
			} else if (e[1] == '7') {
				this.exampleCode2 = '0000001'
			} else if (e[1] == '8') {
				this.exampleCode2 = '00000001'
			} else if (e[1] == '1') {
				this.exampleCode2 = '1'
			}

			if (e[2] == '3') {
				this.exampleCode3 = '002'
			} else if (e[2] == '2') {
				this.exampleCode3 = '02'
			} else if (e[2] == '4') {
				this.exampleCode3 = '0002'
			} else if (e[2] == '5') {
				this.exampleCode3 = '00002'
			} else if (e[2] == '6') {
				this.exampleCode3 = '000002'
			} else if (e[2] == '7') {
				this.exampleCode3 = '0000002'
			} else if (e[2] == '8') {
				this.exampleCode3 = '00000002'
			} else if (e[2] == '1') {
				this.exampleCode3 = '2'
			}

			if (e[3] == '3') {
				this.exampleCode4 = '003'
			} else if (e[3] == '2') {
				this.exampleCode4 = '03'
			} else if (e[3] == '4') {
				this.exampleCode4 = '0003'
			} else if (e[3] == '5') {
				this.exampleCode4 = '00003'
			} else if (e[3] == '6') {
				this.exampleCode4 = '000003'
			} else if (e[3] == '7') {
				this.exampleCode4 = '0000003'
			} else if (e[3] == '8') {
				this.exampleCode4 = '00000003'
			} else if (e[3] == '1') {
				this.exampleCode4 = '3'
			}

			if (e[4] == '3') {
				this.exampleCode5 = '004'
			} else if (e[4] == '2') {
				this.exampleCode5 = '04'
			} else if (e[4] == '4') {
				this.exampleCode5 = '0004'
			} else if (e[4] == '5') {
				this.exampleCode5 = '00004'
			} else if (e[4] == '6') {
				this.exampleCode5 = '000004'
			} else if (e[4] == '7') {
				this.exampleCode5 = '0000004'
			} else if (e[4] == '8') {
				this.exampleCode5 = '00000004'
			} else if (e[4] == '1') {
				this.exampleCode5 = '4'
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.item_s {
	margin-bottom: 10px;
}

.table-big {
	width: 100%;
	height: 320px;
	overflow: auto;
	border: 1px solid #eee;
	margin-top: 10px;

	.table {
		min-width: 3000px;
		height: 400px;

		// white-space:nowrap;
		.table-div {
			vertical-align: top;
			display: inline-block;
			width: 120px;
			border-right: 1px solid #eee;

			div {
				height: 30px;
				line-height: 30px;
				width: 100%;
				// display: inline-block;
				display: flex;
				white-space: nowrap;
				overflow: hidden;
				font-size: 14px;
				color: #333;
				padding: 0 5px;
			}

			.iconfont icon-shanchu {
				cursor: pointer;
			}

			&:first-child .top_icon i {
				display: none;
			}

			&:first-child {
				width: 26px !important;
			}
		}

		.top_icon {
			width: 100%;
			border-bottom: 1px solid #eee;

			i {
				font-size: 20px;
				color: #F56C6C;
				cursor: pointer;
				// margin-top: 7px;
			}

		}

		.table_icon {
			border-bottom: 1px solid #eee;

			i {
				font-size: 20px;
				color: #F56C6C;
				cursor: pointer;
				// margin-top: 7px;
			}

		}
	}

}

.common_dialog_box .item {
	margin-left: 0px !important;
	// width:48% !important
}

.common_dialog_box .common_title {
	font-weight: 600;
}

.tableHead {
	display: inline-block;
	width: 120px;
	text-align: center;
	border-right: 1px solid #eee;
	height: 16px
}</style>


